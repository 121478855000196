import "./App.css";
import React from "react";
import { useState } from "react";

import { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import HeaderBar from "./components/HeaderBar/HeaderBar";
import BottomBar from "./components/BottomBar/BottomBar";
import AboutPage from "./components/AboutPage/AboutPage";
import Etykietki from "./components/Etykietki/Etykietki";
import Etykietka from "./components/etk/Etykietka";

export class AppHelper {
  static storageUrl = "./contentstorage/";
  static ReturnLanguageContent(contentObject, LanguageContext) {
    if (LanguageContext === "pl") {
      return contentObject.pl;
    } else {
      return contentObject.en;
    }
  }
}

export const LanguageContext = React.createContext();

function App() {
  const [selectedLanguage, setSelectedLanguage] = useState(`pl`);
  const [localization, setLocalization] = useState(null);
  const [content, setContent] = useState(null);
  const [viewCardActive, setViewCardActive] = useState(null);

  React.useEffect(() => {
    if (localization === null) {
      loadLocalization();
    }

    if (content === null) {
      loadContent();
    }
  }, [localization, content]);

  function loadLocalization() {
    // console.log("loadLocalization");
    fetch(`${AppHelper.storageUrl}structure/localization.json`, {
      headers: {
        "Content-Type": "application/json",

        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setLocalization(myJson);
      });
  }

  function loadContent() {
    fetch(`${AppHelper.storageUrl}structure/content.json`, {
      headers: {
        "Content-Type": "application/json",

        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setContent(myJson);
      });
  }

  if (localization === null || content === null) {
    return <div>loading</div>;
  } else {
    return (
      <Fragment>
        <LanguageContext.Provider value={selectedLanguage}>
          <BrowserRouter>
            <HeaderBar
              localization={localization}
              setViewCardActive={setViewCardActive}
              viewCardActive={viewCardActive}
            />
            <Routes>
              <Route
                path="/"
                element={
                  <Etykietki
                    localization={localization}
                    viewCardActive={viewCardActive}
                    setViewCardActive={setViewCardActive}
                  ></Etykietki>
                }
              ></Route>
              <Route
                path="/About"
                element={<AboutPage content={content}></AboutPage>}
              ></Route>
              <Route
                exact
                path="/:id"
                element={
                  <Etykietka
                    localization={localization}
                    viewCardActive={viewCardActive}
                    setViewCardActive={setViewCardActive}
                  />
                }
              ></Route>
            </Routes>
          </BrowserRouter>
          <BottomBar></BottomBar>
        </LanguageContext.Provider>
      </Fragment>
    );
  }
}

export default App;
