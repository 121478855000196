import { Fragment } from "react";
import React from "react";
import { useState } from "react";
import FilterSelector from "./Filters/FilterSelector";
import GridDisplay from "./GridDisplay/GridDisplay";
import NextPrevBrowse from "./NextPrevBrowse/NextPrevBrowse";
import { AppHelper } from "../../App";

function Etykietki(props) {
  const [loaded, setLoaded] = useState(false);
  const [etykietyList, setEtykietyList] = useState(null);
  const [etykietySelection, setEtykietySelection] = useState(null);
  const [currentSelection, setCurrentSelection] = useState([0, 20]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterError, setFilterError] = useState(false);

  if (etykietyList === null) {
    loadEtykiety();
  }

  function loadEtykiety() {
    // console.log("loadEtykiety");
    fetch(`${AppHelper.storageUrl}structure/etykietylist.json`, {
      headers: {
        "Content-Type": "application/json",

        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setEtykietyList(myJson);
      });
  }

  React.useEffect(() => {
    if (
      etykietyList !== null &&
      etykietySelection !== null &&
      loaded === false
    ) {
      setLoaded(true);
    }
  }, [etykietyList, loaded, etykietySelection]);

  React.useEffect(() => {
    if (etykietySelection !== null) {
      setEtykietySelection(loadSelection(currentSelection));
    }

    if (etykietySelection === null && etykietyList !== null) {
      setEtykietySelection(loadSelection(currentSelection));
    }
  }, [etykietyList, currentSelection]);

  function reloadEtykietySelection() {
    setEtykietySelection(loadSelection(currentSelection));
    setCurrentSelection([0, 20]);
  }

  function returnCurrentSelection(list, currentSelection) {
    let start = currentSelection[0];
    let count = currentSelection[1];
    const resultList = list.slice(start, start + count);
    return resultList;
  }

  function loadSelection(currentSelection) {
    if (selectedFilters.length === 0) {
      return returnCurrentSelection(etykietyList.content, currentSelection);
    }

    let filteredEtykietyList = etykietyList.content;

    for (let i = 0; i < selectedFilters.length; i++) {
      let filter = selectedFilters[i];

      let newList = filteredEtykietyList.filter(function (item) {
        let thisone = Object.values(item);

        if (thisone.includes(filter) === true) {
          return thisone;
        }
      });
      console.log(newList);
      filteredEtykietyList = newList;
    }

    if (filteredEtykietyList.length !== 0) {
      return filteredEtykietyList;
    } else {
      let x = [];
      return x;
    }
  }

  if (loaded === false) {
    return <div></div>;
  } else {
    return (
      <Fragment>
        <FilterSelector
          localization={props.localization}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          reloadEtykietySelection={reloadEtykietySelection}
          setCurrentSelection={setCurrentSelection}
        ></FilterSelector>
        <GridDisplay
          etykietySelection={etykietySelection}
          setViewCardActive={props.setViewCardActive}
          localization={props.localization}
        ></GridDisplay>
        <NextPrevBrowse
          setCurrentSelection={setCurrentSelection}
          currentSelection={currentSelection}
          etykietyList={etykietyList}
        ></NextPrevBrowse>
      </Fragment>
    );
  }
}

export default Etykietki;
