import React from "react";
import classes from "./FilterDropdownOption.module.css";
import { useState } from "react";

function FilterDropdownOption(props) {
  const [isSelected, setIsSelected] = useState(null);

  if (isSelected === null) {
    if (props.selectedFilters.includes(props.option) === true) {
      setIsSelected(true);
    }
  }

  function handleFilter() {
    let a = props.selectedFilters;
    let b = props.option;

    if (a.includes(b) === true) {
      a.splice(a.indexOf(b), 1);
    } else {
      a.push(b);
    }

    props.setSelectedFilters(a);

    setIsSelected(!isSelected);
  }

  return (
    <div
      onClick={handleFilter}
      className={
        props.selectedFilters.includes(props.option)
          ? classes.optionselected
          : classes.option
      }
    >
      <div className={classes.optiontext}>{props.option}</div>
    </div>
  );
}

export default FilterDropdownOption;
