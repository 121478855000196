import React from "react";
import classes from "./NextPrevBrowse.module.css";
import { useState } from "react";

function NextPrevBrowse(props) {
  const [end, setEnd] = useState(false);

  function NextArrowIcon() {
    return (
      <svg
        className={classes.arrico}
        width="28"
        height="14"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        transform="rotate(-90 0 0)"
      >
        <path
          d="M1.00001 1L4.78887 4.75L8.57773 1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function PrevArrowIcon() {
    return (
      <svg
        className={classes.arrico}
        width="28"
        height="14"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        transform="rotate(90 0 0)"
      >
        <path
          d="M1.00001 1L4.78887 4.75L8.57773 1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  let amount = props.currentSelection[1];
  let currentpos = props.currentSelection[0];
  let totallength = props.etykietyList.content.length;

  if (currentpos + amount >= totallength && end === false) {
    setEnd(true);
  } else if (currentpos + amount <= totallength && end === true) {
    setEnd(false);
  }
  function Next() {
    let x = currentpos + amount;
    let z = [x, amount];
    props.setCurrentSelection(z);
  }

  function Prev() {
    let x;
    if (currentpos !== 0) {
      let x = currentpos - amount;
      let z = [x, amount];
      props.setCurrentSelection(z);
    } else {
      let x = 0;
      let z = [x, amount];
      props.setCurrentSelection(z);
    }
  }

  return (
    <div className={classes.nextprevbar}>
      {currentpos === 0 ? (
        <div />
      ) : (
        <div className={classes.prev} onClick={Prev}>
          {PrevArrowIcon()}
        </div>
      )}
      <div></div>

      {end ? (
        <div />
      ) : (
        <div className={classes.next} onClick={Next}>
          {NextArrowIcon()}
        </div>
      )}
    </div>
  );
}

export default NextPrevBrowse;
