import React, { useContext } from "react";
import classes from "./HeaderBar.module.css";
import { Outlet, Link, Route } from "react-router-dom";

import { AppHelper } from "../../App";
import { LanguageContext } from "../../App";

function HeaderBar(props) {
  function returnMain() {
    if (props.viewCardActive !== null) {
      props.setViewCardActive(null);
    }
  }

  return (
    <div className={classes.bar}>
      <div className={classes.headerbar}>
        <div onClick={returnMain} className={classes.headerbartitle}>
          <Link to="/">
            {AppHelper.ReturnLanguageContent(
              props.localization.header_title.content,
              useContext(LanguageContext)
            )}
          </Link>
        </div>
        <div className={classes.headerbarsubtitle}>
          {AppHelper.ReturnLanguageContent(
            props.localization.header_subtitle.content,
            useContext(LanguageContext)
          )}
        </div>
        <div className={classes.headerbarlangselect}></div>
        <div className={classes.headerbaraboutlink}>
          <Link to="/About">
            {AppHelper.ReturnLanguageContent(
              props.localization.header_about.content,
              useContext(LanguageContext)
            )}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HeaderBar;
