import React from "react";
import classes from "./Etykietka.module.css";
import { AppHelper } from "../../App";
import { useParams } from "react-router-dom";

function Etykietka(props) {
  console.log(props);

  let { id } = useParams();
  console.log(id);

  async function fetchLabel(filename) {
    const response = await fetch(
      `${AppHelper.storageUrl}structure/etykietylist.json`
    );
    const labels = await response.json();
    console.log(labels);
    const label = labels.content.find((obj) => obj.filename === filename);
    console.log(label);
    return label;
  }

  async function checkPropsAssignLabelInformation(labelID) {
    let ding = await fetchLabel(labelID);
    console.log(ding);
    props.setViewCardActive(ding);
  }

  console.log(props);

  if (props.viewCardActive === null) {
    console.log("is null");
    checkPropsAssignLabelInformation(id);
  }

  // function returnMain() {
  //   props.setViewCardActive(null);
  // }

  // function ReturnSelect() {
  //   return (
  //     <svg
  //       className={classes.arrico}
  //       width="28"
  //       height="14"
  //       viewBox="0 0 10 6"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //       transform="rotate(90 0 0)"
  //     >
  //       <path
  //         d="M1.00001 1L4.78887 4.75L8.57773 1"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //     </svg>
  //   );
  // }

  if (props.viewCardActive === null) {
    return <></>;
  } else {
    return (
      <div className={classes.viewcard}>
        {/* <div onClick={returnMain} className={classes.return}>
        <div className={classes.return}>
          <div className={classes.returnico}>{ReturnSelect()}</div>
        </div>
      </div> */}
        <div className={classes.row}>
          <div className={classes.etykieta}>
            <img
              className={classes.etykietaimg}
              src={`${AppHelper.storageUrl}media/EGDEtykietyLarge/${props.viewCardActive.filename}.png`}
              alt="."
            ></img>
          </div>
          <div className={classes.etykietadescription}>
            <table className={classes.descriptiontable}>
              <tr className={classes.tableheader}>
                <th colSpan="2">Karta Wzoru</th>
              </tr>
              <tr>
                <th className={classes.tablecatsheader}>Tytuł</th>
                <th className={classes.tablecontents}>
                  {props.viewCardActive.title}
                </th>
              </tr>
              <tr>
                <th className={classes.tablecatsheader}>Fabryka</th>
                <th className={classes.tablecontents}>
                  {props.viewCardActive.productionlocation}
                </th>
              </tr>
              <tr>
                <th className={classes.tablecatsheader}>Rok produkcji</th>
                <th className={classes.tablecontents}>
                  {props.viewCardActive.productionyear}
                </th>
              </tr>
              <tr>
                <th className={classes.tablecatsheader}>Tematyka</th>
                <th className={classes.tablecontents}>
                  {props.viewCardActive.subject1}{" "}
                  {props.viewCardActive.subject2}
                  {props.viewCardActive.subject3}
                </th>
              </tr>
              <tr>
                <th className={classes.tablecatsheader}>Rozmiar</th>
                <th className={classes.tablecontents}>
                  {props.viewCardActive.size}
                </th>
              </tr>
              <tr>
                <th className={classes.tablecatsheader}>Orientacja</th>
                <th className={classes.tablecontents}>
                  {props.viewCardActive.orientation}
                </th>
              </tr>
              <tr>
                <th className={classes.tablecatsheader}>Numer kat.</th>
                <th className={classes.tablecontents}>
                  {props.viewCardActive.filename}
                </th>
              </tr>
              <tr>
                <th className={classes.tablecatsheader}>Główny kolor</th>
                <th className={classes.tablecontents}>
                  {props.viewCardActive.color}
                </th>
              </tr>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Etykietka;
