import React from "react";
import classes from "./ViewFilterError.module.css";
import { LanguageContext } from "../../../App";
import { AppHelper } from "../../../App";
import { useContext } from "react";

function ViewFilterError(props) {
  console.log(props.localization.griddisplay.content);
  return (
    <div className={classes.window}>
      {AppHelper.ReturnLanguageContent(
        props.localization.griddisplay.content,
        useContext(LanguageContext)
      )}
    </div>
  );
}

export default ViewFilterError;
