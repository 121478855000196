import React from "react";
import classes from "./AboutPage.module.css";
import { Fragment } from "react";
import { LanguageContext } from "../../App";
import { AppHelper } from "../../App";
import { useContext } from "react";

function AboutPage(props) {
  let langcontext = useContext(LanguageContext);

  function RenderAboutContent(content) {
    let array = [];
    for (let a = 0; a < content.length; a++) {
      let b = content[a];

      if (b.type === "paragraph") {
        array.push(
          <div className={classes.paragraph}>
            {AppHelper.ReturnLanguageContent(b.content, langcontext)}
          </div>
        );
      } else if (b.type === "header") {
        array.push(
          <div className={classes.header}>
            {AppHelper.ReturnLanguageContent(b.content, langcontext)}
          </div>
        );
      }
    }
    return <Fragment>{array}</Fragment>;
  }

  return (
    <div className={classes.mainbox}>
      {RenderAboutContent(props.content.about)}

      <div className={classes.end}></div>
    </div>
  );
}

export default AboutPage;
