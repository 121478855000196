import React from "react";
import classes from "./BottomBar.module.css";

import logomkdin from "../../assets/MKiDN_logo-01.svg";
import logolicense from "../../assets/CC4.svg";

function BottomBar(props) {
  return (
    <div className={classes.bar}>
      <div className={classes.inline}>
        <div></div>
        <div className={classes.inline_mkdin_logo}>
          <a href="https://bip.mkidn.gov.pl/" target="_blank">
            <img
              className={classes.mkdinlogoimg}
              src={logomkdin}
              alt={"error"}
            ></img>
          </a>
        </div>
        <div className={classes.inlineitem}>
          <div className={classes.inlinelicense}>
            <a
              href="https://creativecommons.org/licenses/by-nc-sa/4.0/deed.en"
              target="_blank"
            >
              <img
                className={classes.mkdinlogoimg}
                src={logolicense}
                alt="error"
              />
            </a>
          </div>
        </div>

        <div className={classes.inlineitem}>
          <div className={classes.inlineyear}>2023</div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default BottomBar;
