import React from "react";
import classes from "./Card.module.css";
import { useState } from "react";
import { AppHelper } from "../../../App";
import { Link } from "react-router-dom";

function Card(props) {
  const [changed, setChanged] = useState(false);
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");

  let w = props.etykieta.smallwidth;
  let h = props.etykieta.smallheight;
  let orientation = props.etykieta.orientation;

  if (changed === false) {
    if (orientation === "vertical") {
      if (h >= 250) {
        setHeight("250");
        setChanged(true);
      }
    } else {
      if (w >= 250) {
        setWidth("250");
        setChanged(true);
      }
    }
  }

  function viewCard() {
    props.setViewCardActive(props.etykieta);
  }

  return (
    <div className={classes.cardmain}>
      <Link
        to={`/${props.etykieta.filename}`}
        onClick={viewCard}
        className={classes.card}
      >
        <img
          className={classes.etykietaimg}
          src={`${AppHelper.storageUrl}media/EGDEtykietySmall/${props.etykieta.filename}.png`}
          width={width}
          height={height}
          alt="."
        ></img>
      </Link>
    </div>
  );
}

export default Card;
