import React from "react";
import { useState } from "react";
import classes from "./FilterSelector.module.css";
import FilterDropdown from "./FilterDropdown";

import { useContext } from "react";
import { LanguageContext } from "../../../App";
import { AppHelper } from "../../../App";

function Filter(props) {
  function handleToggle() {
    if (
      props.dropDownActive === null ||
      props.dropDownActive !== props.object.content.en
    ) {
      props.setDropDownActive(props.object.content.en);
    } else if (props.dropDownActive === props.object.content.en) {
      props.setDropDownActive(null);
    }
  }

  function ArrowIcon() {
    return (
      <svg
        className={classes.arrico}
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.00001 1L4.78887 4.75L8.57773 1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <div>
      <div className={classes.filteroption}>
        <div onClick={handleToggle} className={classes.filteroptiontext}>
          {`${AppHelper.ReturnLanguageContent(
            props.object.content,
            useContext(LanguageContext)
          )}   `}
          {ArrowIcon()}
        </div>
      </div>
      {props.dropDownActive === props.object.content.en ? (
        <FilterDropdown
          options={props.object.options}
          selectedFilters={props.selectedFilters}
          setSelectedFilters={props.setSelectedFilters}
        />
      ) : (
        <div />
      )}
    </div>
  );
}

export default Filter;
