import React from "react";
import classes from "./GridDisplay.module.css";
import Card from "./Card";
import ViewFilterError from "./ViewFilterError";

function GridDisplay(props) {
  // console.log(props.etykietySelection);

  function isEtykietySelectionEmpty(etykietySelection) {
    return etykietySelection === 0;
  }

  return (
    <div>
      <div className={classes.fillertop}></div>
      {isEtykietySelectionEmpty(props.etykietySelection) ? (
        <div>
          <ViewFilterError localization={props.localization} />
        </div>
      ) : (
        <div className={classes.griddisplay}>
          {props.etykietySelection.length === 0 ? (
            <ViewFilterError localization={props.localization} />
          ) : (
            props.etykietySelection.map((object) => {
              return (
                <Card
                  key={object.filename}
                  etykieta={object}
                  setViewCardActive={props.setViewCardActive}
                />
              );
            })
          )}
        </div>
      )}

      <div className={classes.fillerbottom}></div>
    </div>
  );
}

export default GridDisplay;
