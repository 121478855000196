import React from "react";
import classes from "./FilterSelector.module.css";
import { useState } from "react";
import Filter from "./Filter";
import { scryRenderedComponentsWithType } from "react-dom/test-utils";

function FilterSelector(props) {
  const [filt, setFilt] = useState(null);
  const [dropDownActive, setDropDownActive] = useState(false);

  React.useEffect(() => {
    if (filt === null) {
      assembleFilt();
    }
  }, [filt]);

  function assembleFilt() {
    let categories = props.localization.categories.content;

    let filters = [
      props.localization.selector_year,
      props.localization.selector_subject,
      props.localization.selector_size,
      props.localization.selector_color,
      props.localization.selector_factory,
      props.localization.selector_orientation,
    ];

    filters.forEach(assembleCategory);

    function assembleCategory(currentValue, index, arr) {
      let x = `${currentValue.content.en}s`;

      let y = {
        options: categories[x],
      };

      Object.assign(filters[index], y);
    }

    setFilt(filters);
  }

  function handleReset() {
    props.setSelectedFilters([]);
    props.setCurrentSelection([0, 20]);
    setDropDownActive(false);
  }

  function applyFilters() {
    props.reloadEtykietySelection();
  }

  if (filt !== null) {
    return (
      <div className={classes.box}>
        <div className={classes.filters}>
          <div className={classes.filtersarray}>
            {filt.map((object) => {
              return (
                <Filter
                  key={object.content.en}
                  object={object}
                  dropDownActive={dropDownActive}
                  setDropDownActive={setDropDownActive}
                  selectedFilters={props.selectedFilters}
                  setSelectedFilters={props.setSelectedFilters}
                />
              );
            })}
            <div onClick={handleReset} className={classes.filteroption}>
              <div className={classes.filteroptiontext}>odśwież</div>
            </div>
            <div onClick={applyFilters} className={classes.filteroption}>
              <div className={classes.filteroptiontext}>zastosuj</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FilterSelector;
