import React from "react";
import classes from "./FilterDropdown.module.css";
import FilterDropdownOption from "./FilterDropdownOption";
import { Fragment } from "react";

function FilterDropdown(props) {
  return (
    <div className={classes.dropdown}>
      <div className={classes.dropdownoptions}>
        {props.options.map((option, index) => {
          return (
            <FilterDropdownOption
              key={index}
              option={option}
              selectedFilters={props.selectedFilters}
              setSelectedFilters={props.setSelectedFilters}
            ></FilterDropdownOption>
          );
        })}
      </div>
    </div>
  );
}

export default FilterDropdown;
